<template>
  <div>
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="bonusLogs"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="bonusLogsMeta.total_count"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-btn color="primary" @click="paginateTo">
                <v-icon>mdi-autorenew</v-icon>
                Оновити дані
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | getShortTime }}
        <span class="caption">{{ item.created_at | getShortDate }}</span>
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.amount > 0 ? `Нарахування бонусів${item.company ? ', L' : ', G'}` : 'Бонусний платіж' }}
      </template>
      <template v-slot:item.amount="{ item }">
        <span class="font-weight-bold" :class="item.amount > 0 ? 'green--text' : 'red--text'">{{ item.amount }}</span>
      </template>
      <template v-slot:item.payment_status="{ item }">
        <span v-if="item.payment">{{ getPaymentStatus(item.payment.payment_status) }}</span>
      </template>
      <template v-slot:item.created_by="{ item }">
        <div v-if="item.created_by">
          <router-link
            v-if="userId"
            :to="{
              name: 'UserCard',
              params: { id: currentCompanyId, userId: item.user.id },
            }"
            class="text-decoration-none"
          >
            {{ item.created_by.id }}, {{ item.created_by.first_name }} {{ item.created_by.last_name }}
          </router-link>
          <div v-else>
            <span v-if="item.payment"
              >{{ item.created_by.id }}, {{ item.created_by.first_name }} {{ item.created_by.last_name }}</span
            >
            <span v-else>{{ item.created_by.id }}, тех. підтримка</span>
          </div>
        </div>
        <div v-if="item.promotion">
          <router-link
            v-if="userId"
            :to="{
              name: 'PromotionCard',
              params: { id: currentCompanyId, promotionId: item.promotion.id },
            }"
            class="text-decoration-none"
          >
            {{ item.promotion.id }}, акція
          </router-link>
          <span v-else>{{ item.promotion.id }}, акція</span>
        </div>
      </template>
      <template v-slot:item.receiver="{ item }">
        <span v-if="item.payment && item.payment.device">автомат</span>
        <span v-else>користувач</span>
      </template>
      <template v-slot:item.receiver_name="{ item }">
        <div v-if="item.payment && item.payment.device">
          <router-link
            v-if="userId"
            :to="{
              name: 'DeviceCard',
              params: { id: currentCompanyId, deviceId: item.payment.device.id },
            }"
            class="text-decoration-none"
          >
            {{ getDeviceTitle(item.payment.device.type) }} {{ item.payment.device.id }}
          </router-link>
          <span v-else>{{ getDeviceTitle(item.payment.device.type) }} {{ item.payment.device.id }}</span>
        </div>
        <div v-else>
          <router-link
            v-if="userId"
            :to="{
              name: 'UserCard',
              params: { id: currentCompanyId, userId: item.user.id },
            }"
            class="text-decoration-none"
          >
            {{ item.user.id }}, {{ item.user.first_name }} {{ item.user.last_name }}
          </router-link>
          <span v-else>{{ item.user.id }}, {{ item.user.first_name }} {{ item.user.last_name }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import sortUtils from '@/mixins/sortUtils'
import convertDate from '@/mixins/convertDate'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'BonusLogs',

  mixins: [convertDate, convertDevicesTypes, sortUtils],

  props: {
    userId: {
      type: Number,
      default: 0,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        { text: 'Створено', value: 'created_at', sortable: true, width: 110 },
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Сума', value: 'amount', sortable: true, align: 'right' },
        { text: 'Статус', value: 'payment_status', sortable: false },
        { text: 'id, Ініціатор', value: 'created_by', sortable: false },
        { text: 'Тип сутності отримувача', value: 'receiver', sortable: false },
        { text: 'id, Назва сутності отримувача', value: 'receiver_name', sortable: false },
      ],
      loading: false,
      options: {},
      sortBy: 'created_at',
      sortDesc: true,
      payload: null,
      fromDate: null,
      toDate: null,
      textFieldProps: {
        hideDetails: 'auto',
        dense: true,
      },
      datePickerProps: {
        'first-day-of-week': 1,
      },
      timePickerProps: {
        format: '24hr',
        useSeconds: true,
      },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('companies', ['currentCompany']),
    ...mapState('bonuses', ['bonusLogs', 'bonusLogsMeta']),
    ...mapState('dictionaries', ['paymentStatusesDictionary', 'machinesTypesDictionary']),

    currentCompanyId() {
      return this.currentCompany?.id || null
    },
  },

  methods: {
    ...mapActions('bonuses', ['getBonusLogs']),

    initialize() {
      this.payload = {
        companyId: this.currentCompanyId,
      }
    },

    async paginateTo() {
      this.loading = true
      this.payload = {
        ...this.payload,
        company: this.isClient ? this.currentCompanyId : null,
        userId: this.userId,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        createdAt: {
          fromDate: this.fromDate,
          toDate: this.toDate,
        },
      }
      try {
        await this.getBonusLogs(this.payload)
      } finally {
        this.loading = false
      }
    },

    getPaymentStatus(id) {
      return this.paymentStatusesDictionary.find((item) => item.value === id)?.name || ''
    },
  },
}
</script>
